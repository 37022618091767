import { Component, Show } from 'solid-js';

interface ActivityIndicatorProps {
  progress?: number;
  size?: number;
}

const ActivityIndicator: Component<ActivityIndicatorProps> = (props) => {
  const size = props.size || 72;
  const borderSize = Math.round(size / 7.2);

  const spinnerStyles = `
    @keyframes rotate {
      0% {
        transform: rotate(0deg);
      }
      100% {
        transform: rotate(360deg);
      }
    }

    .spinner {
      display: inline-block;
      width: ${size}px;
      height: ${size}px;
      border: ${borderSize}px solid transparent;
      border-top-color: var(--primary-color); 
      border-bottom-color: var(--primary-color);
      border-radius: 50%;
      position: relative;
      animation: rotate 2s linear infinite;
    }
  `;

  return (
    <>
      <style>{spinnerStyles}</style>
      <div class="relative flex size-full items-center justify-center">
        <div class="spinner" />
        <Show when={props.progress}>
          <div class="absolute flex items-center justify-center">{props.progress}%</div>
        </Show>
      </div>
    </>
  );
};

export default ActivityIndicator;
